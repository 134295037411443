<template>
  <div class="upload-box">
    <div v-if="busy">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div
      v-else
      class="upload-field text-xl"
      :class="{ 'upload-field__dragging': isDragging }"
      @click="selectFiles"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <i class="fas fa-upload"></i>
    </div>

    <input
      ref="upload"
      type="file"
      multiple
      :disabled="isLoading"
      @change="uploadFiles()"
      class="input-file hidden"
    >
  </div>
</template>

<script>
export default {
  name: 'Upload',
  components: {},
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingCount: 0,
      isDragging: false,
    };
  },
  methods: {
    selectFiles() {
      this.$refs.upload.click();
    },
    uploadFiles() {
      const files = [].slice.call(this.$refs.upload.files);
      files.forEach((file) => {
        this.$emit('upload', file);
      });
    },
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    onDragLeave() {
      this.isDragging = false;
    },
    onDrop(event) {
      event.preventDefault();
      this.$refs.upload.files = event.dataTransfer.files;
      this.isDragging = false;
      this.uploadFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
  .upload-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    min-height: 250px;

    &:hover {
      cursor: pointer;
    }

    .upload-field {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .upload-field:hover, .upload-field__dragging {
      background-color: #efefef;
    }
  }
</style>
